import React, { useState, useEffect } from "react";
import "./App.css";
import Data from "./data/productData"; 

function App() {
  const [foodState, setFoodState] = useState();

  const onChangeComboBox = (e) => {
    const selectedId = e.target.value;
    const selectedFoodState = Data.filter((d) => d.id == selectedId)[0];
    setFoodState(selectedFoodState);
  };

  useEffect(() => {
    setFoodState(Data[2]);
  }, []);
  return (
    
    <div className="container m-5">
      <select
        className="custom-select"
        value={foodState?.id}
        onChange={(e) => {
          onChangeComboBox(e);
        }}
      >
        {Data.map((d) => (
          <option key={d.id} value={d.id}>
            {d.Name}
          </option>
        ))}
      </select>
  
      <div className="container1">
      
      {foodState ? (
        <p ><h1>{foodState?.Name}</h1><br />Price: Rs. {foodState?.Price} <br /><h3>Item Id: {foodState?.id}</h3></p>
        
      ) : (
        <p>Shirt 3 <br />Price: Rs. 150 <br />Item Id:3 </p>
       
      )}
      </div>
    </div>
  );
  
}
export default App;
